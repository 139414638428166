// material
import { Box, BoxProps } from '@mui/material';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const { themeMode } = useSettings();
  return (
    <Box sx={{ width: 140, ...sx }}>
      <img src="/img/payagold.png" alt={`${process.env.REACT_APP_NAME} Logo`} />
    </Box>
  );
}
